import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import assignIn from "lodash/assignIn";
import groupBy from "lodash/groupBy";
import { cast, flow, IAnyModelType, Instance, isAlive, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";
import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { Import, ImportType } from "@rollup-api/models";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { CommentThreadedItem } from "@rollup-api/models/comments/threadedComments.model";
import { RequirementBlockType } from "@rollup-api/models/requirementBlock";
import { CreateRequirementsPageDto, RequirementsPageUpdateDto } from "@rollup-api/models/requirementsPage";
import { CreateTableColumnDto } from "@rollup-api/models/table-column/creteTableColumnDto";
import { TableColumn } from "@rollup-api/models/table-column/tableColumn.model";
import { updateRequirementsPageDebounced } from "@rollup-api/utils";
import { NoReturnGenerator } from "@rollup-types/typeUtils";
import appStore from "@store/AppStore";
import { CommentFeedStore } from "@store/CommentFeedStore";
import { IRequirementBlock, IRequirementBlockMobxType, RequirementBlockStore } from "@store/Requirements/RequirementBlockStore";
import { ITableColumn, ITableColumnSnapshotIn, TableColumnStore } from "@store/TableColumnStore";
import { EntityType } from "@store/types";
import { moveItemInRefArray, parentWorkspace, validatedRefArray } from "@utilities";
import { isEnum } from "@utilities/EnumUtils";
import { isDefined } from "@utilities/TypeGuards";

import { rollupClient } from "../../core/api";
import { mapRtoToSnapshot } from "../../services/services.utils";

export const isHeading = (type: RequirementBlockType): boolean => {
  return [RequirementBlockType.h1, RequirementBlockType.h2, RequirementBlockType.h3].includes(type);
};

interface IRequirementsPageStoreVolatile {
  lastFocusedCommentId?: string;
}

// these are the columns that don't need to be created in the backend, as they cannot
// be resized, hidden, or reordered
const excludedMetaColumns: ERequirementsTableColumn[] = [
  ERequirementsTableColumn.CHECKBOX,
  ERequirementsTableColumn.ACTIONS,
  // Do not create column for DESCRIPTION, as it was replaced by REQUIREMENT_STATEMENT and only kept for backward compatibility
  ERequirementsTableColumn.DESCRIPTION,
];

const metaColumns: CreateTableColumnDto[] = Object.values(ERequirementsTableColumn)
  .filter(column => !excludedMetaColumns.includes(column))
  .map((metaColumn, orderIndex) => ({ metaColumn, orderIndex }));

// TODO: Tech debt: Create a UI sub-model once the tree stuff has been fixed
export const RequirementsPageStore = types
  .model("RequirementsPage", {
    id: types.identifier,
    replicated: types.optional(types.boolean, true),
    label: types.optional(types.string, "Untitled Page"),
    docNumber: types.optional(types.string, "ROL"),
    columnMap: types.map(TableColumnStore),
    requirementBlocks: types.array(types.safeReference<IRequirementBlockMobxType>(types.late((): IAnyModelType => RequirementBlockStore))),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
    commentFeed: types.optional(CommentFeedStore, {}),
  })
  .volatile<IRequirementsPageStoreVolatile>(() => ({
    lastFocusedCommentId: undefined,
  }))
  .views(self => ({
    get columns(): ITableColumn[] {
      return toArray<ITableColumn>(self.columnMap);
    },
    get orderedColumns(): ITableColumn[] {
      return this.columns.slice().sort((a, b) => a.orderIndex - b.orderIndex);
    },
    hasStatusColumn(statusDefinitionId: string): boolean {
      return this.columns.some(column => column.entity?.id === statusDefinitionId);
    },
  }))
  .actions(self => ({
    setReplicated() {
      self.replicated = true;
    },
    patch(update: RequirementsPageUpdateDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "requirementBlocks", "replicated"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setColumns(columns: TableColumn[]) {
      columns
        .map(mapRtoToSnapshot<TableColumn, ITableColumnSnapshotIn>)
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .forEach(column => self.columnMap.put(column));
    },
    addBlock(id: string, orderIndex?: number): string | undefined {
      if (orderIndex !== undefined) {
        const originalBlocksOrder = self.requirementBlocks.slice();
        self.requirementBlocks.splice(orderIndex, 0, id);
        return originalBlocksOrder.at(orderIndex)?.id;
      } else {
        self.requirementBlocks.push(id);
      }
    },
    deleteRequirementBlock(block: IRequirementBlock): boolean {
      if (block && self.requirementBlocks?.includes(block)) {
        const hasBlockInAgGrid = !!appStore.env.requirementsTableGridApi?.getRowNode(block.id);
        hasBlockInAgGrid && appStore.env.requirementsTableGridApi?.applyTransaction({ remove: [{ id: block.id } as IRequirementBlock] });
        self.requirementBlocks.remove(block);
        return parentWorkspace(self)?.deleteRequirementBlock(block) ?? false;
      } else {
        return false;
      }
    },
    setLabel(label: string) {
      if (label === self.label) {
        return;
      }

      self.label = label;
      updateRequirementsPageDebounced(self.id, { label });
    },
    setDocNumber(docNumber: string) {
      if (docNumber === self.docNumber) {
        return;
      }

      self.docNumber = docNumber;
      updateRequirementsPageDebounced(self.id, { docNumber });
    },
    moveBlock(srcId: string, destId: string, notify = true) {
      if (self.requirementBlocks) {
        const srcIndex = self.requirementBlocks.findIndex(i => i?.id === srcId);
        const destIndex = self.requirementBlocks.findIndex(i => i?.id === destId);
        moveItemInRefArray(self.requirementBlocks, srcIndex, destIndex);
        if (notify) {
          rollupClient.requirementBlocks.reorder(srcId, destId).catch((err: Error) => {
            showApiErrorToast("Error reordering requirement", err);
          });
        }
      }
    },
    clearCommentHistory() {
      self.commentFeed = cast({});
    },
    addMetaColumn(metaColumn: ERequirementsTableColumn) {
      const newColumnDto = {
        id: uuidv4(),
        metaColumn,
        orderIndex: self.columns.length,
      } satisfies CreateTableColumnDto;

      self.columnMap.put({ ...newColumnDto, tableId: self.id });
      rollupClient.tableColumns.create(self.id, newColumnDto);
    },
    addStatusColumn(statusDefinitionId: string): string {
      const newColumnDto = {
        id: uuidv4(),
        entity: { id: statusDefinitionId, type: EntityType.StatusDefinition },
        orderIndex: self.columns.length,
      } satisfies CreateTableColumnDto;

      self.columnMap.put({ ...newColumnDto, tableId: self.id });
      rollupClient.tableColumns.create(self.id, newColumnDto);
      return newColumnDto.id;
    },
    removeColumn(columnId: string, notify = true) {
      if (!self.columnMap.has(columnId)) {
        return;
      }

      self.columnMap.delete(columnId);

      if (notify) {
        rollupClient.tableColumns.delete(self.id, columnId);
      }
    },
    toggleColumn(columnId: string) {
      const column = self.columnMap.get(columnId);
      column?.toggleHide();
    },
    createRequirementFromCsv: flow(function* (attachmentId: string, file: File, columnMap: string, workspaceId: string) {
      try {
        const result: Import = yield rollupClient.imports.create({
          type: ImportType.Requirement,
          attachmentId,
          workspaceId,
          originalFileName: file.name,
          columnMap,
          entityId: self.id,
        });
        if (!result) {
          showApiErrorToast("Error creating csv-based requirement", new Error());
          return;
        }
        showToast("Successfully started requirement import process", "success", "info-sign");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error creating csv-based requirement", error as Error);
      }
    }),
    setLastFocusedCommentId(id?: string) {
      self.lastFocusedCommentId = id;
    },
  }))
  .actions(self => ({
    loadDefaultMetaColumns: flow(function* (): NoReturnGenerator<AxiosResponse<TableColumn[]>> {
      const response = yield rollupClient.tableColumns.bulkCreate(self.id, metaColumns);
      self.setColumns(response.data);
    }),
  }))
  .views(self => ({
    getReqBlockIdsWithComments(removeHeadings?: boolean): string[] {
      return self.requirementBlocks
        .filter(isDefined)
        .filter(block => block.hasComments)
        .filter(block => !removeHeadings || !isHeading(block.type))
        .map(block => block.id);
    },
    get allValidBlockIds(): string[] {
      return self.requirementBlocks.filter(isDefined).map(block => block.id);
    },
    get validatedBlocks() {
      return validatedRefArray<IRequirementBlock>(self.requirementBlocks).filter(block => isAlive(block));
    },
    get validatedBlockIds() {
      return this.validatedBlocks.map(block => block.id);
    },
    get blocksWithoutStaticId() {
      return this.validatedBlocks.filter(block => !block.staticId);
    },
    get blocksWithStaticId() {
      return this.validatedBlocks.filter(block => block.staticId);
    },
    getCreateDto(): CreateRequirementsPageDto {
      return {
        id: uuidv4(),
        label: self.label ? `Copy of ${self.label}` : "",
      };
    },
    isValidColumn(column: TableColumn): boolean {
      return !!(column.entity || (column.metaColumn && isEnum(ERequirementsTableColumn, column.metaColumn)));
    },
  }))
  .actions(self => ({
    fetchReqBlockComments: flow(function* fetch(): Generator<any, void, AxiosResponse<CommentThreadedItem[]>> {
      if (!self.validatedBlocks.length) {
        return;
      }

      try {
        const { data } = yield rollupClient.comments.retrieveThreaded({
          parentIds: self.validatedBlockIds,
          childTake: 9999,
          type: CommentLocationType.Annotation,
          parentTake: 1,
          childSkip: 0,
          temporalDirection: TemporalDirection.Older,
        });
        data.forEach(({ parentId, threadedComments }) => {
          const requirementBlock = self.validatedBlocks.find(p => p?.id === parentId);
          requirementBlock?.annotationList.loadThreadedComments(threadedComments);
        });
      } catch (error) {
        throw new Error(`Error fetching requirement block comments for block ${self.id}: ${error}`);
      }
    }),
    loadColumns: flow(function* (): NoReturnGenerator<AxiosResponse<TableColumn[]>> {
      try {
        const res = yield rollupClient.tableColumns.getAll(self.id);
        const columns = res.data;
        const { validColumns = [], invalidColumns = [] } = groupBy(columns, column =>
          self.isValidColumn(column) ? "validColumns" : "invalidColumns"
        );
        if (invalidColumns.length) {
          // TODO use deleteMany endpoint
          invalidColumns.forEach(column => rollupClient.tableColumns.delete(self.id, column.id));
        }
        if (validColumns.length) {
          self.setColumns(columns);
          return;
        }
        self.loadDefaultMetaColumns();
      } catch (err) {
        console.error(err);
        showApiErrorToast("Error fetching columns");
      }
    }),
  }));

export function subscribeToRequirementsPageEvents(socket: Socket) {
  socket.on("createRequirementsPage", (data: { workspaceId: string; createRequirementsPageDto: CreateRequirementsPageDto }) => {
    if (data.createRequirementsPageDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { label, id } = data.createRequirementsPageDto;
      appStore.workspaceModel.requirementsModule.createRequirementsPage({ id, label }, false);
    }
  });

  socket.on("deleteRequirementsPage", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const page = appStore.workspaceModel.requirementsModule.get(data.id);
      if (page) {
        appStore.workspaceModel.deleteRequirementsPage(page.id, false);
      }
    }
  });

  socket.on("updateRequirementsPage", (data: { workspaceId: string; id: string; updateRequirementsPageDto: RequirementsPageUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const page = appStore.workspaceModel.requirementsModule.get(data.id);
      page?.patch(data.updateRequirementsPageDto);
    }
  });
}

export interface IRequirementsPage extends Instance<typeof RequirementsPageStore> {}
export interface IRequirementsPageSnapshotIn extends SnapshotIn<typeof RequirementsPageStore> {}
interface IRequirementsPageSnapshotOut extends SnapshotOut<typeof RequirementsPageStore> {}
export interface IRequirementsPageMobxType extends IType<IRequirementsPageSnapshotIn, IRequirementsPageSnapshotOut, IRequirementsPage> {}
